@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
:root {
  --primary-color: #c72727;
  --secondary-color: black;
  --light-color: #f3f3f3;
  --dark-color: #333;
  --max-width: 1100px;
 
}
.container {
  max-width: var(--max-width);
  margin: auto;
  padding: 0 2rem;
  overflow: hidden;
}
.wrong-answer {
  color: var(--primary-color);
}
.countdown {
  background: #272668;
  display: inline-block;
  color: #fff;
  font-size: 1.2rem;
  text-align: center;
  padding: 2.5rem 0.4rem;
  border-radius: 15px;
  margin: 0.5rem;
  align-content: center;
}

.position-display {
  background: #272668;
  display: inline-block;
  color: #fff;
  font-size: 1rem;
  text-align: center;
  padding: 0.8rem 0.6rem;
  border-radius: 15px;
  margin: 0.1rem;
  align-content: center;
}
.quiz-header {
  border: 2px solid #272668;
  border-radius: 15px;
  padding: 30px;
  margin: 20px;
  position: relative;
  text-align: left;
  font-family: "Poppins", serif !important;
  z-index: 2;
}
.quiz-result-header{
  border: 2px solid #272668;
  border-radius: 15px;
  padding: 30px;
  margin: 20px;
  position: relative;
  text-align: left;
  font-family: "Poppins", serif !important;
  z-index: 2;
}

.quiz-result-header h2 {
  font-size: 30px;
  font-weight: 600;
  color: rgb(41, 83, 155);
  text-align: center;
}
.quiz-result-header h5 {
  font-size: 22px;
}



.quiz-header h2 {
  font-size: 30px;
  font-weight: 600;
  color: rgb(41, 83, 155);
  text-align: center;
}
.quiz-header h5 {
  font-size: 22px;
}
.que-header-text {
  font-family: Poppins, sans-serif !important;
  font-style: normal !important;
  font-weight: 400px;
  letter-spacing: 0px;
  font-size: 1rem;
}

.exam-info {
  display: grid;
  grid-template-columns: 5fr 3fr;
  margin: 2rem 0;
  grid-gap: 1.5rem;
}

.result-info {
  display:grid;
  grid-template-columns: 2fr 2fr;
  margin: 2rem 0;
  grid-gap:1.5rem;
}

.question-main-div {
  margin-top: 40px;
  display: flex;
  justify-content: center;
  width: 80%;
  border-radius: 10px;
  box-shadow: 0 8px 16px 0 rgb(0 0 0 / 5%), 0 6px 20px 0 rgb(0 0 0 / 5%);
}
.question-mbl-view {
  margin-top: 10px;
  width: 100%;
  border-radius: 10px;
}

.timer-header {
  font-family: Poppins, sans-serif !important;
  font-style: normal !important;
  font-weight: 300px bold !important;
  letter-spacing: 0px;
  font-size: 2rem;
}

.timer-count-down {
  font-family: Poppins, sans-serif !important;
  font-style: normal !important;
  font-weight: 400 !important;
  letter-spacing: 0px;
  font-size: 1.5rem;
}

.question-shown {
  display: flex;
  justify-content: start;
  font-family: Poppins, sans-serif !important;
  font-style: normal !important;
  font-weight: 600 !important;
  letter-spacing: 0px;
  font-size: 1.2rem;
  color: var(--secondary-color);
}
.ques-ans-selected {
  background-color: #0066cc !important;
  width: 30px !important;
  height: 30px !important;
  margin-left: 25px !important;
}
.question-ans-row:hover {
  background-color: #82828221 !important;
  padding: 10px !important;
}
.ques-main-div-selected {
  background-color: #82828221 !important;
}
.ques-ans-Unselected {
  margin-left: 25px !important;
  background-color: white !important;
  border: 1px solid #0066cc !important;
  color: black !important;
  width: 30px !important;
  height: 30px !important;
}
.ques-result-selected {
  background-color: #00cc77 !important;
  width: 30px !important;
  height: 30px !important;
  margin-left: 25px !important;
}

.ques-result-wrong {
  background-color: #d81c2f !important;
  width: 30px !important;
  height: 30px !important;
  margin-left: 25px !important;
  color: white !important;
}

.ques-result-unselected {
  margin-left: 25px !important;
  width: 30px !important;
  height: 30px !important;

  background-color: white;
  border: 1px solid black;
  color: black;
}

.ques-ans-Unselected:hover {
  background-color: #0066cc !important;
  width: 30px !important;
  height: 30px !important;
  color: white !important;
}

.hover-item:hover {
  cursor: pointer !important;
}
.total-question {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 10px !important;
}
.que-top-shadow {
  border-top: 2px solid rgba(22, 19, 19, 0.377) !important;
  padding-top: 10px;
}
.que-top-shadow:hover {
  background-color: #82828221 !important;
}
.question-main-footer {
  margin-top: 40px;
  display: flex;
  justify-content: center;
  width: 80%;
  border-radius: 10px;
  box-shadow: 0 8px 16px 0 rgb(0 0 0 / 5%), 0 6px 20px 0 rgb(0 0 0 /5%);
  margin-bottom: 40px;
  padding: 20px;
}

.centerXY {
  display: flex;
  justify-content: center;
  align-items: center;
}

.badge-text-footer {
  padding: 10px 20px;
}

.badge-text-footer-apps {
  padding: 12px;
}

.correct-answer-mcq {
  border-bottom: 2px solid green;
  border-top: 2px solid green !important;
  background-color: rgba(0, 128, 0, 0.226) !important;
  color: black !important;
}

.bg-green {
  background-color: rgba(0, 128, 21, 0.493) !important;
}

.bg-red {
  background-color: rgba(246, 10, 10, 0.1) !important;
  border: 2px solid rgba(246, 10, 10, 0.507);
}


/*
Buttons
*/

.btn {
    display: inline-block;
    border: none;
    background: var(--secondary-color);
    color: #fff;
    padding: 0.5rem 1.5rem;
  }
  
  .footer-btn-group {
      display: flex;
      justify-content: space-between;
      padding: 0.2rem 2rem;
  }

  .btn svg {
    margin-right: 8px;
  }
  
  .btn-reverse {
    background: #fff;
    color: #000;
  }
  
  .btn-block {
    width: 100%;
    margin:1rem;
    background: #000;
  }
  
  .btn:hover {
    transform: scale(0.98);
    color: #fff;
  }

  .btn-group {
      display:grid;
      grid-template-columns: repeat(100, 100%)
  
  }

  .btn-group button {
      margin: 0.2rem;
      padding: 0.21rem;
  }
  .result-break-down {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }


.quiz-result-main-small {
  margin-top: 20px;
}

/* question card*/

.question-card {
  background: var(--light-color);
  padding: 2rem;
  border-radius: 2rem;
  margin-bottom: 2rem;
}


.result-answer-index {
  position: relative;
  width: 50px;
  height: 50px;
  padding: 10px;
  text-align: center;
  border-radius: 50%;
  font-size: 19px;
  border: 1px #eee solid;
  transition: 0.3s;
  background: var(--secondary-color);
  color: #fff;
}


.result-answer-index-wrong{
  position: relative;
  width: 50px;
  height: 50px;
  padding: 10px;
  text-align: center;
  border-radius: 50%;
  font-size: 19px;
  border: 1px #eee solid;
  transition: 0.3s;
  background: red;
  color: #fff;
}

.result-answer-index-correct{
  position: relative;
  width: 50px;
  height: 50px;
  padding: 10px;
  text-align: center;
  border-radius: 50%;
  font-size: 19px;
  border: 1px #eee solid;
  transition: 0.3s;
  background: green;
  color: #fff;
}


.result-answer-index-UnAnswered{
  position: relative;
  width: 50px;
  height: 50px;
  padding: 10px;
  text-align: center;
  border-radius: 50%;
  font-size: 19px;
  border: 1px #eee solid;
  transition: 0.3s;
  background: gray;
  color: #fff;
}

/* App view  */
.result-answer-index-smallScreen{
  position: relative;
  width: 35px;
  height: 35px;
  padding: 6px;
  text-align: center;
  border-radius: 50%;
  font-size: 12px;
  border: 1px #eee solid;
  transition: 0.3s;
  background: var(--secondary-color);
  color: #fff;
  margin-left: -10px;
}

.result-answer-index-wrong-smallScreen{
  position: relative;
  width: 35px;
  height: 35px;
  padding: 5px;
  text-align: center;
  border-radius: 50%;
  font-size: 12px;
  border: 1px #eee solid;
  transition: 0.3s;
  background: red;
  color: #fff;
}

.result-answer-index-correct-smallScreen{
  position: relative;
  width: 35px;
  height: 35px;
  padding: 5px;
  text-align: center;
  border-radius: 50%;
  font-size: 12px;
  border: 1px #eee solid;
  transition: 0.3s;
  background: green;
  color: #fff;
}


.result-answer-index-UnAnswered-smallScreen{
  position: relative;
  width: 35px;
  height: 35px;
  padding: 5px;
  text-align: center;
  border-radius: 50%;
  font-size: 12px;
  border: 1px #eee solid;
  transition: 0.3s;
  background: gray;
  color: #fff;
}

/* End App View */


@media (max-width: 800px) {
    .quiz-header h2 {
        font-size: 20px;
      }
      .quiz-header h5 {
        font-size: 16px;
      }

  .quiz-result-main {
    margin-top: 100px;
  }
  .quiz-footer-button {
    margin-top: 10px !important;
  }
}

.correct-ans-small-text {
  display: flex;
  justify-content: center;
  margin-top: 5px;
}



.small-result-box {
  width: 30px;
  height: 30px;
  margin: 0px auto;
  display: block;
  font-size: 10px;
  border: 1px solid black;
  border-radius: 10px;
  position: relative;
}

.small-result-box-icon {
  background-color: white;
  color: green;
  position: absolute;
  top: -5px;
  right: -6px;
  font-size: 10px;
}

.app-que-avatar-size {
  width: 20px !important;
  height: 25px !important;
  font-size: 10px !important;
}

h1,
h2,
h3,
h4 {
  font-weight: 600;
  margin-bottom: 10px;
}

.marks-distribution {
  font-size: small;
}

.question-header {
  display: grid;
  grid-template-columns: repeat(2, 50%);
  gap: 2rem;
}

.question-header-title {
  color: rgb(39, 133, 211);
}

.question-inner-header {
  display: grid;
  grid-template-columns: repeat(2, 50%);
}
.marks-distribution {
  color: crimson;
}

.timer-info {
  color: rgb(39, 133, 211);
}

.result-outlined-box {
  width: 100px;
  height: 100px;
  margin: 0 auto;
  display: block;
  border: 1px solid black;
  border-radius: 10px;
  padding: 20px 10px;
  position: relative;
}


@media (max-width: 576px) {

  .quiz-result-header{
    display: grid;
    grid-template-columns: 1fr;
    border: none;
    margin: 0;
    padding: 0;
    position: relative;
    text-align: left;
    font-family: "Poppins", serif !important;
    z-index: 2;
  }

  .quiz-result-header h2{
    font-size: 20px;
  }
  .quiz-result-header h5{
    font-size: 16px;
  }

  .position-display {
    background: #272668;
    display: inline-block;
    color: #fff;
    font-size: 1rem;
    text-align: center;
    border-radius: 15px;
    margin: 0.1rem;
    align-content: center;
  }
  .number-inside-outline{
    margin-top: -10px;
  }
  .result-break-down {
    display: flex;
    justify-content: space-between;
    margin-left: -40px;
  }
  .result-info {
    display:grid;
    grid-template-columns: 1fr;
    margin: 1rem 0;
    grid-gap:0.5rem;
  }
  .exam-info {
    grid-template-columns: 1fr;
  }
  .question-header {
    display: flex;
    flex-direction: column;
  }
  .question-inner-header {
    display: flex;
    flex-direction: column;
  }
  .result-outlined-box {
    width: 50px;
    height: 50px;
    margin: 0 auto;
    display: block;
    border: 1px solid black;
    border-radius: 10px;
    padding: 5px 2px;
    position: relative;
  }
  .quiz-header {
    margin-top: 100px;
    margin: 1rem;
  }
  .badge-text-footer {
    display: flex;
    justify-content: center;
  }
  .timer-header {
    font-family: Poppins, sans-serif !important;
    font-style: normal !important;
    font-weight: 300px bold !important;
    letter-spacing: 0px;

    font-size: 1rem;
  }

  .mark-dist {
    font-size: 0.6rem;
  }

  .result-break-down h1 {
    font-size: 2rem;
  }
}
