@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import "~react-component-countdown-timer/lib/styles.css";
@import url('https://fonts.googleapis.com/css2?family=STIX+Two+Text&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
   
   
}

.centerXY{
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
.centerX{
    display: flex !important;
    justify-content: center !important;
}
.centerX{
    display: flex !important;
    align-items: center !important;
}

/* Typography */

h1 {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 56px;
    line-height: 62px;
}

h2 {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    line-height: 53px;
}

h3 {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 44px;
}

h4 {

    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 35px;
}

h5 {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 26px;
}

h6 {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 22px;
}

.ltb {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 0px !important;
}

.ltr {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 0px !important;
}

.mtb {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    margin-bottom: 0px !important;
}

.mtr {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 25px;
    margin-bottom: 0px !important;
}

.ntb {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    margin-bottom: 0px !important;
}

.ntr {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 0px !important;
}

.stb {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 0px !important;
}

.str {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
 margin-bottom: 0px !important;
}

/* Typography */


.mgin{
    margin-left: 10px;
    margin-top: 10px;
}
.inline{
    display: inline;
}
.centerFlex{
    display: flex;
    justify-content: center;
}
.logo {
    font-family: 'Poppins', sans-serif;
    height: 100%;
    display: flex;
    align-items: center;
    margin: 0 !important;
}
.logo :hover{
    cursor: pointer !important;
}

.p-auth-active {
    font-family: 'Poppins', sans-serif;
    font-size: 22px;
    font-weight: 500;
    color: #263238 !important;
    margin: 40px 40px 30px 20px;
    width: 30px;
    border-bottom: 3px solid #0066CC;
}

.p-auth-activeS {
    font-family: 'Poppins', sans-serif;
    font-size: 22px;
    font-weight: 500;
    color: #263238 !important;
    margin: 40px 40px 30px 0px;
    width: 30px;
    border-bottom: 3px solid #0066CC;
}

.p-auth {
    font-family: 'Poppins', sans-serif;
    font-size: 22px;
    font-weight: 500;
    color: #AAAAAA;
    margin: 40px 40px 10px 20px;
}

.logo a {}

header {
    padding: 12px 0;
    position: fixed;
    background-color: #fff;
    width: 100vw;
    z-index: 9999;
}

.header-text {
    font-family: 'Poppins', sans-serif;
    margin-top: 110px;
}

.menu {}

.menu ul {
    display: flex;
    padding: 0;
    list-style: none;
    margin-bottom: 0;
    justify-content: center;
}

.menu ul li {}

.menu ul a {
    text-decoration: none;
    font-size: 17px;
    color: #3a3a3aab;
    font-weight: 500;
    padding: 15px;
    display: block;
}

.menu ul a:hover {
    color: #0066CC;
}

.header-buttons {
    font-family: 'Poppins', sans-serif;
    display: flex;
    height: 100%;
    justify-content: flex-end;
    align-items: center;
}

.theme-btn {
    font-family: 'Poppins', sans-serif;
    background: #0066CC;
    color: #fff;
    font-weight: 400;
    border-radius: 10px;
    padding: 4px 15px;
    width: 50%;
    margin: 5px;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
}

.theme-btn-white {
    background: white;
    color: #0066CC;
    font-weight: 400;
    border-radius: 10px;
    padding: 4px 15px;
    width: 50%;
    margin: 5px;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
}

footer {
    font-family: 'Poppins', sans-serif;
    background-image: linear-gradient(#0066CC, #174067);
    padding-top: 60px;
    padding-bottom: 20px;
    bottom: 0 !important;
}


.footer-left {}

.footer-logo {}

.footer-logo a {
    display: block;
}

.footer-logo a img {}

.social {
    display: flex;
    margin: 20px 0;
}

.social a {
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 5px;
    text-decoration: none;
    margin-right: 10px;
}

.social a:nth-child(1) {
    color: #0066CC;
}

.social a:nth-child(2) {
    color: #dc3545;
}

.social a:nth-child(3) {
    color: #0066CC;
}

.social a i {}

.copyright {
    color: #fff;
    font-weight: 500;
}

.footer-menu {}

.footer-menu h1 {
    color: #fff;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 1px;
    margin-bottom: 10px;
}

.footer-menu ul {
    padding: 0;
    list-style: none;
}

.footer-menu ul li {}

.footer-menu ul li a {
    text-decoration: none;
    color: #fff;
    font-size: 15px;
}

.footer-menu img {
    width: 130px;
}

.center {
    text-align: center;
}

.form-control {
    border-radius: 4px;
    width: 100%;
    padding-left: 20px;
    outline: 0;
    color: #4F4F4F !important;
}
.form-label{
    font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 22px;
color: #BDBDBD;
}
.form-control:focus {
    color: #212529 !important;
    background-color: #fff;
    border: 1px solid #0066CC !important;
    outline: 0;
    box-shadow: 0 0 0 0.1px rgb(13 110 253 / 25%);
}

.padding {
    padding: 0px 25px;
}

.hero {
    padding: 0;
    background-color: #F7F8FA;
    margin-bottom:100px;
}

.card1 {
    width: 100%;
    border-radius: 16px;
    text-align: center!important;
    box-shadow: 0 0.5rem 1.5rem 0.5rem rgb(0 0 0 / 8%) !important;
    background-color: #fff;
    height: 140px;
    margin-top: 25px;
}

.card1img {
    margin-top: 20px;
}

.mt-3 {
    font-size: 12px;
}

.course {
    background-color: #fff;
    height: 600px;
}

.card {
    background-color: #F7F8FA;
    border-radius: 6px;
    margin: 50px 20px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset !important;
}

.cardimg {
    margin-bottom: 15px;
    margin-top: 10px;
    height: 100%;
}

.cardp {
    margin-left: 30px;
    font-size: 14px;
    display: flex;
    align-items: center;
}

.cardp span:first-child {
    margin-right: 19px;
    width: 25px;
}

.btnc {
    border-radius: 10px;
    background-color: #F7F8FA;
    border: 1px solid #0066CC;
    margin-left: 10%;
    width: 80%;
    height: 50px;
    margin-bottom: 20px;
}

.cardm {
    border-radius: 11px;
    background-color: #fff !important;
    margin-top: 13px !important;
    margin-bottom: 10px !important;
    height: 300px;
    width: 200px;
    margin-right: 30px;
    box-shadow: 0 0.5rem 1.5rem 0.5rem rgb(0 0 0 / 8%) !important;
}

.heros {
    padding: 0;
    height: 530px;
    background-color: #F7F8FA;
}

.cardmimg {
    background-color: #ffff;
    background-position: center;
    background-size: cover;
    border-radius: 11px 11px 0 0;
    height: 170px;
    width: 200px;
    margin-left: 0px;
}

.nopadding {
    padding: 0 !important;
    margin: 0 !important;
}

.speciality {
    background-color: #fff;
}

.cards {
    background-color: #fff;
    box-shadow: 0 0.5rem 1.5rem 0.5rem rgb(0 0 0 / 8%) !important;
    border-radius: 5px;
}

.loginpana {
    margin-top: 233px;
}


.goalHero {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 17.88%, #E2EEFB 100%);
    min-height: 400px;
}

.goalSection {
    min-height: 500px;
}

.container {
    max-width: 1200px !important;
    padding-left: 35px !important;
}

.goalP {
    font-size: 16px;
    font-weight: 500;
    padding: 6px 0px;
    padding: 0px 14px;
    padding-top: 15px;
}
.goalP :hover{
    cursor: pointer !important;
}

.goalPA {
    font-size: 16px;
    font-weight: 400;
    padding: 0px 14px;
    padding-top: 15px;
    color: #636363;
}
.goalPA:hover{
    cursor: pointer;
}

.demoCard {
    background-color: #D6D6D6;
    width: 100%;
    box-shadow: 4px 4px 7px -3px rgba(0, 0, 0, 0.15);
    border-radius: 9px;
    height: 130px;
}

.authL {
    margin-top: 30px;
    margin-left: 20px;
}

.authR {
    margin-top: 20px;
    margin-left: 20px;
}


.demoBox {
    background: #C4C4C4;
    border-radius: 12px;
    width: 302px;
    height: 302px;
}

.demoBoxCourse {
    background: #C4C4C4;
    border-radius: 12px;
    max-width: 500px;
    width : 100%;
    height: auto;
}

.PaymentCard{
width: 320px;
height: 128px;
background: #F7F8FA;
border-radius: 8px;
margin-top: 15px !important;
box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset;
}
.PurchaseCard{
    padding: 5px;
    width: 320px;
height: 285px;
background: #F7F8FA;
border-radius: 8px;
margin-top: 15px;
box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset;
}

.homepage-img{
    width: 178px;
height: 200px;
    background: #C4C4C4;
    box-shadow: 4px 4px 19px -7px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    margin-right: 8px;
    margin-top: 16px;
}
.center1{
    display: flex;
    justify-content: center;
    width: 100%;
    height: calc(100% / (16/9));
}

.responsive-iframe {
	max-width: 100%;
	padding-top: 56.25%;
	position: relative;
	width: 100%;
}

.responsive-iframe iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}


.llveClass{
    background: #FFFFFF;
box-shadow: 5px 4px 20px rgba(0, 0, 0, 0.1);
border-radius: 13px;
width: 575px;
height: 322px;
}
.sidebar{
    width: 500px;
    height: 80vh;
    min-width: 300px;
    background: #FFFFFF;
    box-shadow: 0px 4px 5px 1px rgba(0, 0, 0, 0.12);
}
.mainbar{
    height: 80vh;
    width: 100%;

}
.classBox{
    min-width: 300px;
    height: 75px;
    background: #fff;
    }
.classBoxA{
    min-width: 300px;
height: 75px;
background: #EFEFEF;
}
.bdr{
    border: 1px solid #F8F8F8
}
.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    color: #0066CC;
    background-color: #fff;
    border-bottom: 2px solid #0066CC;
}
.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    border-top: none !important;
    border-right: none !important;
    border-left: none !important;
}
.nav-tabs .nav-link {
    color: #636363;
    border-top: none !important;
    border-right: none !important;
    border-left: none !important;
    border-bottom: none ;
}
.cardD{
    background: #FFFFFF;
    box-shadow: 4px 4px 20px -3px rgba(0, 0, 0, 0.15);
    border-radius: 9px;
    min-height: 100px;
    width: 100%;
    margin-top: 10px;
}
.cardx{
    background: #F8F8F8;
border-radius: 8px;
min-height: 100px;
width: 96%;
}
@media screen and (max-width: 576px) {
    /* .row>* {
        max-width: 90% !important;
        margin-top: 12px;
    } */
    .graduate {
        display: none !important;
    }
    
}

@media screen and (max-width: 980px) {
    .graduate {
        width: 442px;
    }
    .pana {
        display: none;
    }
    .card1 {
        margin-top: 10px;
    }
    .hero {
        height: 450px !important;
    }
    .container{
        padding-left: 20px !important;
    }
}

@media screen and (max-width: 830px) {
    .loginpana {
        display: none;
    }
    .none {
        display: none !important;
    }
}

@media screen and (max-width: 765px) {
    .graduate {
        width: 328px !important;
    }
    .goalSection1 {
        height: fit-content !important;
        border: 0px !important;
    }
    .goalpana {
        width: 350px !important;
    }
}


.border-div{
    border : 1px solid rgb(82, 81, 81);
    border-radius: 20px;
}
.centerX-div-flex{
  display: flex;
  justify-content: center;
}


.Edit-goals-text :hover
{
   cursor: pointer;
   color: blue;
}

.main-modal-div{
    margin-top: 60px !important;
    z-index: 9999;
}


@media (min-width: 576px)
{
    .modal-dialog {
        max-width: 800px !important;
        margin: 1.75rem auto !important;
       
    }
   
}

@media (max-width: 576px)
{
    .main-modal-div{
        margin-top: 70px !important;
    } 
    #dropdown-button-dark-example2{
        width: 300px !important;
      }
}


/* DROP DOWN */
#dropdown-button-dark-example2{
    text-align: left !important;
    background-color:#fff !important;
    color: #0000008f;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    margin-bottom: 0px !important;
    max-width: 50px !important;
    background-color: #fff !important;
}

.classBoxUnselect:hover
{
    cursor: pointer !important;
    background-color: rgb(248, 244, 244) !important;
    color: rgb(15, 13, 13) !important;
    min-width: 300px;
    min-height: 75px;
    
}

.classBoxUnselect
{
    background-color: FFFFFF !important;
    color: rgb(15, 13, 13) !important;
    min-width: 300px;
    min-height: 75px;
    
}
.classBoxSelect{
    
    background-color: rgb(245, 237, 237) !important;
    color: rgb(15, 13, 13) !important;
    min-width: 300px;
    min-height: 75px;

}
.recorded-class-title
{
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 25px;
    font-family: 'Poppins', sans-serif;
}

/* IFRAME CSS CLASS FROM (.PHP FILE )*/

[style*="--aspect-ratio"] > :first-child {
    width: 100%;
  }
  [style*="--aspect-ratio"] > img {
    height: auto;
  }
  @supports (--custom:property) {
    [style*="--aspect-ratio"] {
      position: relative;
    }
    [style*="--aspect-ratio"]::before {
      content: "";
      display: block;
      padding-bottom: calc(100% / (var(--aspect-ratio)));
    }
    [style*="--aspect-ratio"] > :first-child {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
    }
  }
  
  iframe, .video-error{
       margin: 0 auto;
       display: block;
  }
  .video-error{
       text-align: center;
       color: white;
       top: calc(50% - 10px)!important;
  }

  .dropdown-menu-item{
      width: 300px !important;
      padding: 20px !important;
  }

.goal-bold-text{
      font-size: 1rem !important;
      font-family: Montserrat !important;
      font-weight: 600 !important;
  }
  .goal-sublist{
    font-size: 1rem !important;
    font-family: Montserrat !important;
    font-weight: 500 !important;
  }
  .add-goal-text{
    font-size: 1rem !important;
    font-family: Montserrat !important;
    font-weight: 600 !important;
    padding: 20px !important;
  }
  .dropdown-main-box{
     font-size: 1rem !important;
     font-weight: 600 !important;
     font-family: Montserrat !important;
     border-bottom:  0 !important;
     background-color: #fff !important;
  }
  .btn-custom-dropdown{
   border-color: #fff !important;
  }
.btn-custom-dropdown:focus {
    outline: none;
    box-shadow: none;
  }

  .btnc:hover{
  cursor: pointer;
  }

  .hover-item :hover{
      cursor: pointer !important;
  }

.box-shadow{
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.subscriptionBox{
 background-color: #fafafa;
}

.iframe-subscription-buy
{
   width: 100%!important;
   height: 350px !important;
}
.div-minHeight-60vh{
    min-height: 60vh !important;
}
.div-minHeight-100vh{
    min-height: 100vh !important;
}

.event-container-full {
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 576px) {
    .container-small-screen {
        padding-top: 150px !important;
    }
    .list-box-card{
      width: 100% !important;
    }
    .headline-aim-text{
     margin-top: 30px !important;
    }
    .iframe-subscription-buy
    {
     width: auto!important;
     height: auto !important;
    }
    .live-video-main-box{
        padding-top: 150px !important;
    }
}

mjx-container.MathJax {
    pointer-events: none;
  }

