@import url("https://fonts.googleapis.com/css2?family=Noto+Serif:ital@1&display=swap");

.title {
  font-weight: 800;
  font-size: x-large;
  text-align: center;
  font-family: "Noto Serif", serif;
margin-top: 10px;
}

.dialogue {
  background-color: #f9f9f9;
  color: rgb(22, 131, 215);
  margin-bottom: 0;
}

.top-three {
  margin: 2rem;
  padding: 1rem;
  display: grid;
  grid-template-columns: repeat(3,1fr);
}

.card {
  background: #fff;
	margin-bottom: 30px;
  margin-top: 0;
	border-radius: 5px;
	overflow: hidden;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  
}



.card h6 {
  font-weight: 600;
  color: rgb(17, 119, 214);
}
.num-display {
  position: relative;
  width: 60px;
  height: 60px;
  padding: 10px;
  text-align: center;
  text-align: center;
  border-radius: 30%;
  font-size: 19px;
  border: 1px #eee solid;
  transition: 0.3s;
  background: grey;
  color: #fff;
}
.num-display {
  position: absolute;
  top: -10px;
  left: -10px;
}


.num-display-own {
  position: relative;
  width: 60px;
  height: 60px;
  padding: 10px;
  text-align: center;
  text-align: center;
  border-radius: 30%;
  font-size: 19px;
  border: 1px #eee solid;
  transition: 0.3s;
  background: white;
  color: black;
}
.num-display-own {
  position: absolute;
  top: -10px;
  left: -10px;
}

.num-display-top3{
    position: relative;
    width: 60px;
    height: 60px;
    padding: 10px;
    text-align: center;
    border-radius: 30%;
    font-size: 20px;
    border: 1px #eee solid;
    transition: 0.3s;
    background: rgb(1, 1, 54);
    color: #fff;
  }
  .num-display-top3 {
    position: absolute;
    top: -10px;
    left: -10px;
  }
.icon {
  position: absolute;
  top: 10px;
  right: 40px;
  cursor: pointer;
  background: none;
  border: none;
}

.about-link {
  display: flex;
  justify-content: flex-end;
}



@media (max-width: 600px) {
}