@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=STIX+Two+Text&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Noto+Serif:ital@1&display=swap);


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    overflow-x: hidden;
    min-height: 75vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.table-border-wrap table{border-collapse:collapse}.table-border-wrap th,.table-border-wrap td{border:1px solid #000;padding:8px}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
   
   
}

.centerXY{
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
.centerX{
    display: flex !important;
    justify-content: center !important;
}
.centerX{
    display: flex !important;
    align-items: center !important;
}

/* Typography */

h1 {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 56px;
    line-height: 62px;
}

h2 {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    line-height: 53px;
}

h3 {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 44px;
}

h4 {

    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 35px;
}

h5 {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 26px;
}

h6 {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 22px;
}

.ltb {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 0px !important;
}

.ltr {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 0px !important;
}

.mtb {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    margin-bottom: 0px !important;
}

.mtr {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 25px;
    margin-bottom: 0px !important;
}

.ntb {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    margin-bottom: 0px !important;
}

.ntr {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 0px !important;
}

.stb {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 0px !important;
}

.str {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
 margin-bottom: 0px !important;
}

/* Typography */


.mgin{
    margin-left: 10px;
    margin-top: 10px;
}
.inline{
    display: inline;
}
.centerFlex{
    display: flex;
    justify-content: center;
}
.logo {
    font-family: 'Poppins', sans-serif;
    height: 100%;
    display: flex;
    align-items: center;
    margin: 0 !important;
}
.logo :hover{
    cursor: pointer !important;
}

.p-auth-active {
    font-family: 'Poppins', sans-serif;
    font-size: 22px;
    font-weight: 500;
    color: #263238 !important;
    margin: 40px 40px 30px 20px;
    width: 30px;
    border-bottom: 3px solid #0066CC;
}

.p-auth-activeS {
    font-family: 'Poppins', sans-serif;
    font-size: 22px;
    font-weight: 500;
    color: #263238 !important;
    margin: 40px 40px 30px 0px;
    width: 30px;
    border-bottom: 3px solid #0066CC;
}

.p-auth {
    font-family: 'Poppins', sans-serif;
    font-size: 22px;
    font-weight: 500;
    color: #AAAAAA;
    margin: 40px 40px 10px 20px;
}

.logo a {}

header {
    padding: 12px 0;
    position: fixed;
    background-color: #fff;
    width: 100vw;
    z-index: 9999;
}

.header-text {
    font-family: 'Poppins', sans-serif;
    margin-top: 110px;
}

.menu {}

.menu ul {
    display: flex;
    padding: 0;
    list-style: none;
    margin-bottom: 0;
    justify-content: center;
}

.menu ul li {}

.menu ul a {
    text-decoration: none;
    font-size: 17px;
    color: #3a3a3aab;
    font-weight: 500;
    padding: 15px;
    display: block;
}

.menu ul a:hover {
    color: #0066CC;
}

.header-buttons {
    font-family: 'Poppins', sans-serif;
    display: flex;
    height: 100%;
    justify-content: flex-end;
    align-items: center;
}

.theme-btn {
    font-family: 'Poppins', sans-serif;
    background: #0066CC;
    color: #fff;
    font-weight: 400;
    border-radius: 10px;
    padding: 4px 15px;
    width: 50%;
    margin: 5px;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
}

.theme-btn-white {
    background: white;
    color: #0066CC;
    font-weight: 400;
    border-radius: 10px;
    padding: 4px 15px;
    width: 50%;
    margin: 5px;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
}

footer {
    font-family: 'Poppins', sans-serif;
    background-image: linear-gradient(#0066CC, #174067);
    padding-top: 60px;
    padding-bottom: 20px;
    bottom: 0 !important;
}


.footer-left {}

.footer-logo {}

.footer-logo a {
    display: block;
}

.footer-logo a img {}

.social {
    display: flex;
    margin: 20px 0;
}

.social a {
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 5px;
    text-decoration: none;
    margin-right: 10px;
}

.social a:nth-child(1) {
    color: #0066CC;
}

.social a:nth-child(2) {
    color: #dc3545;
}

.social a:nth-child(3) {
    color: #0066CC;
}

.social a i {}

.copyright {
    color: #fff;
    font-weight: 500;
}

.footer-menu {}

.footer-menu h1 {
    color: #fff;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 1px;
    margin-bottom: 10px;
}

.footer-menu ul {
    padding: 0;
    list-style: none;
}

.footer-menu ul li {}

.footer-menu ul li a {
    text-decoration: none;
    color: #fff;
    font-size: 15px;
}

.footer-menu img {
    width: 130px;
}

.center {
    text-align: center;
}

.form-control {
    border-radius: 4px;
    width: 100%;
    padding-left: 20px;
    outline: 0;
    color: #4F4F4F !important;
}
.form-label{
    font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 22px;
color: #BDBDBD;
}
.form-control:focus {
    color: #212529 !important;
    background-color: #fff;
    border: 1px solid #0066CC !important;
    outline: 0;
    box-shadow: 0 0 0 0.1px rgb(13 110 253 / 25%);
}

.padding {
    padding: 0px 25px;
}

.hero {
    padding: 0;
    background-color: #F7F8FA;
    margin-bottom:100px;
}

.card1 {
    width: 100%;
    border-radius: 16px;
    text-align: center!important;
    box-shadow: 0 0.5rem 1.5rem 0.5rem rgb(0 0 0 / 8%) !important;
    background-color: #fff;
    height: 140px;
    margin-top: 25px;
}

.card1img {
    margin-top: 20px;
}

.mt-3 {
    font-size: 12px;
}

.course {
    background-color: #fff;
    height: 600px;
}

.card {
    background-color: #F7F8FA;
    border-radius: 6px;
    margin: 50px 20px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset !important;
}

.cardimg {
    margin-bottom: 15px;
    margin-top: 10px;
    height: 100%;
}

.cardp {
    margin-left: 30px;
    font-size: 14px;
    display: flex;
    align-items: center;
}

.cardp span:first-child {
    margin-right: 19px;
    width: 25px;
}

.btnc {
    border-radius: 10px;
    background-color: #F7F8FA;
    border: 1px solid #0066CC;
    margin-left: 10%;
    width: 80%;
    height: 50px;
    margin-bottom: 20px;
}

.cardm {
    border-radius: 11px;
    background-color: #fff !important;
    margin-top: 13px !important;
    margin-bottom: 10px !important;
    height: 300px;
    width: 200px;
    margin-right: 30px;
    box-shadow: 0 0.5rem 1.5rem 0.5rem rgb(0 0 0 / 8%) !important;
}

.heros {
    padding: 0;
    height: 530px;
    background-color: #F7F8FA;
}

.cardmimg {
    background-color: #ffff;
    background-position: center;
    background-size: cover;
    border-radius: 11px 11px 0 0;
    height: 170px;
    width: 200px;
    margin-left: 0px;
}

.nopadding {
    padding: 0 !important;
    margin: 0 !important;
}

.speciality {
    background-color: #fff;
}

.cards {
    background-color: #fff;
    box-shadow: 0 0.5rem 1.5rem 0.5rem rgb(0 0 0 / 8%) !important;
    border-radius: 5px;
}

.loginpana {
    margin-top: 233px;
}


.goalHero {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 17.88%, #E2EEFB 100%);
    min-height: 400px;
}

.goalSection {
    min-height: 500px;
}

.container {
    max-width: 1200px !important;
    padding-left: 35px !important;
}

.goalP {
    font-size: 16px;
    font-weight: 500;
    padding: 6px 0px;
    padding: 0px 14px;
    padding-top: 15px;
}
.goalP :hover{
    cursor: pointer !important;
}

.goalPA {
    font-size: 16px;
    font-weight: 400;
    padding: 0px 14px;
    padding-top: 15px;
    color: #636363;
}
.goalPA:hover{
    cursor: pointer;
}

.demoCard {
    background-color: #D6D6D6;
    width: 100%;
    box-shadow: 4px 4px 7px -3px rgba(0, 0, 0, 0.15);
    border-radius: 9px;
    height: 130px;
}

.authL {
    margin-top: 30px;
    margin-left: 20px;
}

.authR {
    margin-top: 20px;
    margin-left: 20px;
}


.demoBox {
    background: #C4C4C4;
    border-radius: 12px;
    width: 302px;
    height: 302px;
}

.demoBoxCourse {
    background: #C4C4C4;
    border-radius: 12px;
    max-width: 500px;
    width : 100%;
    height: auto;
}

.PaymentCard{
width: 320px;
height: 128px;
background: #F7F8FA;
border-radius: 8px;
margin-top: 15px !important;
box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset;
}
.PurchaseCard{
    padding: 5px;
    width: 320px;
height: 285px;
background: #F7F8FA;
border-radius: 8px;
margin-top: 15px;
box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset;
}

.homepage-img{
    width: 178px;
height: 200px;
    background: #C4C4C4;
    box-shadow: 4px 4px 19px -7px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    margin-right: 8px;
    margin-top: 16px;
}
.center1{
    display: flex;
    justify-content: center;
    width: 100%;
    height: calc(100% / (16/9));
}

.responsive-iframe {
	max-width: 100%;
	padding-top: 56.25%;
	position: relative;
	width: 100%;
}

.responsive-iframe iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}


.llveClass{
    background: #FFFFFF;
box-shadow: 5px 4px 20px rgba(0, 0, 0, 0.1);
border-radius: 13px;
width: 575px;
height: 322px;
}
.sidebar{
    width: 500px;
    height: 80vh;
    min-width: 300px;
    background: #FFFFFF;
    box-shadow: 0px 4px 5px 1px rgba(0, 0, 0, 0.12);
}
.mainbar{
    height: 80vh;
    width: 100%;

}
.classBox{
    min-width: 300px;
    height: 75px;
    background: #fff;
    }
.classBoxA{
    min-width: 300px;
height: 75px;
background: #EFEFEF;
}
.bdr{
    border: 1px solid #F8F8F8
}
.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    color: #0066CC;
    background-color: #fff;
    border-bottom: 2px solid #0066CC;
}
.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    border-top: none !important;
    border-right: none !important;
    border-left: none !important;
}
.nav-tabs .nav-link {
    color: #636363;
    border-top: none !important;
    border-right: none !important;
    border-left: none !important;
    border-bottom: none ;
}
.cardD{
    background: #FFFFFF;
    box-shadow: 4px 4px 20px -3px rgba(0, 0, 0, 0.15);
    border-radius: 9px;
    min-height: 100px;
    width: 100%;
    margin-top: 10px;
}
.cardx{
    background: #F8F8F8;
border-radius: 8px;
min-height: 100px;
width: 96%;
}
@media screen and (max-width: 576px) {
    /* .row>* {
        max-width: 90% !important;
        margin-top: 12px;
    } */
    .graduate {
        display: none !important;
    }
    
}

@media screen and (max-width: 980px) {
    .graduate {
        width: 442px;
    }
    .pana {
        display: none;
    }
    .card1 {
        margin-top: 10px;
    }
    .hero {
        height: 450px !important;
    }
    .container{
        padding-left: 20px !important;
    }
}

@media screen and (max-width: 830px) {
    .loginpana {
        display: none;
    }
    .none {
        display: none !important;
    }
}

@media screen and (max-width: 765px) {
    .graduate {
        width: 328px !important;
    }
    .goalSection1 {
        height: -webkit-fit-content !important;
        height: -moz-fit-content !important;
        height: fit-content !important;
        border: 0px !important;
    }
    .goalpana {
        width: 350px !important;
    }
}


.border-div{
    border : 1px solid rgb(82, 81, 81);
    border-radius: 20px;
}
.centerX-div-flex{
  display: flex;
  justify-content: center;
}


.Edit-goals-text :hover
{
   cursor: pointer;
   color: blue;
}

.main-modal-div{
    margin-top: 60px !important;
    z-index: 9999;
}


@media (min-width: 576px)
{
    .modal-dialog {
        max-width: 800px !important;
        margin: 1.75rem auto !important;
       
    }
   
}

@media (max-width: 576px)
{
    .main-modal-div{
        margin-top: 70px !important;
    } 
    #dropdown-button-dark-example2{
        width: 300px !important;
      }
}


/* DROP DOWN */
#dropdown-button-dark-example2{
    text-align: left !important;
    background-color:#fff !important;
    color: #0000008f;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    margin-bottom: 0px !important;
    max-width: 50px !important;
    background-color: #fff !important;
}

.classBoxUnselect:hover
{
    cursor: pointer !important;
    background-color: rgb(248, 244, 244) !important;
    color: rgb(15, 13, 13) !important;
    min-width: 300px;
    min-height: 75px;
    
}

.classBoxUnselect
{
    background-color: FFFFFF !important;
    color: rgb(15, 13, 13) !important;
    min-width: 300px;
    min-height: 75px;
    
}
.classBoxSelect{
    
    background-color: rgb(245, 237, 237) !important;
    color: rgb(15, 13, 13) !important;
    min-width: 300px;
    min-height: 75px;

}
.recorded-class-title
{
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 25px;
    font-family: 'Poppins', sans-serif;
}

/* IFRAME CSS CLASS FROM (.PHP FILE )*/

[style*="--aspect-ratio"] > :first-child {
    width: 100%;
  }
  [style*="--aspect-ratio"] > img {
    height: auto;
  }
  @supports (--custom:property) {
    [style*="--aspect-ratio"] {
      position: relative;
    }
    [style*="--aspect-ratio"]::before {
      content: "";
      display: block;
      padding-bottom: calc(100% / (var(--aspect-ratio)));
    }
    [style*="--aspect-ratio"] > :first-child {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
    }
  }
  
  iframe, .video-error{
       margin: 0 auto;
       display: block;
  }
  .video-error{
       text-align: center;
       color: white;
       top: calc(50% - 10px)!important;
  }

  .dropdown-menu-item{
      width: 300px !important;
      padding: 20px !important;
  }

.goal-bold-text{
      font-size: 1rem !important;
      font-family: Montserrat !important;
      font-weight: 600 !important;
  }
  .goal-sublist{
    font-size: 1rem !important;
    font-family: Montserrat !important;
    font-weight: 500 !important;
  }
  .add-goal-text{
    font-size: 1rem !important;
    font-family: Montserrat !important;
    font-weight: 600 !important;
    padding: 20px !important;
  }
  .dropdown-main-box{
     font-size: 1rem !important;
     font-weight: 600 !important;
     font-family: Montserrat !important;
     border-bottom:  0 !important;
     background-color: #fff !important;
  }
  .btn-custom-dropdown{
   border-color: #fff !important;
  }
.btn-custom-dropdown:focus {
    outline: none;
    box-shadow: none;
  }

  .btnc:hover{
  cursor: pointer;
  }

  .hover-item :hover{
      cursor: pointer !important;
  }

.box-shadow{
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.subscriptionBox{
 background-color: #fafafa;
}

.iframe-subscription-buy
{
   width: 100%!important;
   height: 350px !important;
}
.div-minHeight-60vh{
    min-height: 60vh !important;
}
.div-minHeight-100vh{
    min-height: 100vh !important;
}

.event-container-full {
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 576px) {
    .container-small-screen {
        padding-top: 150px !important;
    }
    .list-box-card{
      width: 100% !important;
    }
    .headline-aim-text{
     margin-top: 30px !important;
    }
    .iframe-subscription-buy
    {
     width: auto!important;
     height: auto !important;
    }
    .live-video-main-box{
        padding-top: 150px !important;
    }
}

mjx-container.MathJax {
    pointer-events: none;
  }


:root {
  --primary-color: #c72727;
  --secondary-color: black;
  --light-color: #f3f3f3;
  --dark-color: #333;
  --max-width: 1100px;
 
}
.container {
  max-width: 1100px;
  max-width: var(--max-width);
  margin: auto;
  padding: 0 2rem;
  overflow: hidden;
}
.wrong-answer {
  color: #c72727;
  color: var(--primary-color);
}
.countdown {
  background: #272668;
  display: inline-block;
  color: #fff;
  font-size: 1.2rem;
  text-align: center;
  padding: 2.5rem 0.4rem;
  border-radius: 15px;
  margin: 0.5rem;
  align-content: center;
}

.position-display {
  background: #272668;
  display: inline-block;
  color: #fff;
  font-size: 1rem;
  text-align: center;
  padding: 0.8rem 0.6rem;
  border-radius: 15px;
  margin: 0.1rem;
  align-content: center;
}
.quiz-header {
  border: 2px solid #272668;
  border-radius: 15px;
  padding: 30px;
  margin: 20px;
  position: relative;
  text-align: left;
  font-family: "Poppins", serif !important;
  z-index: 2;
}
.quiz-result-header{
  border: 2px solid #272668;
  border-radius: 15px;
  padding: 30px;
  margin: 20px;
  position: relative;
  text-align: left;
  font-family: "Poppins", serif !important;
  z-index: 2;
}

.quiz-result-header h2 {
  font-size: 30px;
  font-weight: 600;
  color: rgb(41, 83, 155);
  text-align: center;
}
.quiz-result-header h5 {
  font-size: 22px;
}



.quiz-header h2 {
  font-size: 30px;
  font-weight: 600;
  color: rgb(41, 83, 155);
  text-align: center;
}
.quiz-header h5 {
  font-size: 22px;
}
.que-header-text {
  font-family: Poppins, sans-serif !important;
  font-style: normal !important;
  font-weight: 400px;
  letter-spacing: 0px;
  font-size: 1rem;
}

.exam-info {
  display: grid;
  grid-template-columns: 5fr 3fr;
  margin: 2rem 0;
  grid-gap: 1.5rem;
}

.result-info {
  display:grid;
  grid-template-columns: 2fr 2fr;
  margin: 2rem 0;
  grid-gap:1.5rem;
}

.question-main-div {
  margin-top: 40px;
  display: flex;
  justify-content: center;
  width: 80%;
  border-radius: 10px;
  box-shadow: 0 8px 16px 0 rgb(0 0 0 / 5%), 0 6px 20px 0 rgb(0 0 0 / 5%);
}
.question-mbl-view {
  margin-top: 10px;
  width: 100%;
  border-radius: 10px;
}

.timer-header {
  font-family: Poppins, sans-serif !important;
  font-style: normal !important;
  font-weight: 300px bold !important;
  letter-spacing: 0px;
  font-size: 2rem;
}

.timer-count-down {
  font-family: Poppins, sans-serif !important;
  font-style: normal !important;
  font-weight: 400 !important;
  letter-spacing: 0px;
  font-size: 1.5rem;
}

.question-shown {
  display: flex;
  justify-content: start;
  font-family: Poppins, sans-serif !important;
  font-style: normal !important;
  font-weight: 600 !important;
  letter-spacing: 0px;
  font-size: 1.2rem;
  color: black;
  color: var(--secondary-color);
}
.ques-ans-selected {
  background-color: #0066cc !important;
  width: 30px !important;
  height: 30px !important;
  margin-left: 25px !important;
}
.question-ans-row:hover {
  background-color: #82828221 !important;
  padding: 10px !important;
}
.ques-main-div-selected {
  background-color: #82828221 !important;
}
.ques-ans-Unselected {
  margin-left: 25px !important;
  background-color: white !important;
  border: 1px solid #0066cc !important;
  color: black !important;
  width: 30px !important;
  height: 30px !important;
}
.ques-result-selected {
  background-color: #00cc77 !important;
  width: 30px !important;
  height: 30px !important;
  margin-left: 25px !important;
}

.ques-result-wrong {
  background-color: #d81c2f !important;
  width: 30px !important;
  height: 30px !important;
  margin-left: 25px !important;
  color: white !important;
}

.ques-result-unselected {
  margin-left: 25px !important;
  width: 30px !important;
  height: 30px !important;

  background-color: white;
  border: 1px solid black;
  color: black;
}

.ques-ans-Unselected:hover {
  background-color: #0066cc !important;
  width: 30px !important;
  height: 30px !important;
  color: white !important;
}

.hover-item:hover {
  cursor: pointer !important;
}
.total-question {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 10px !important;
}
.que-top-shadow {
  border-top: 2px solid rgba(22, 19, 19, 0.377) !important;
  padding-top: 10px;
}
.que-top-shadow:hover {
  background-color: #82828221 !important;
}
.question-main-footer {
  margin-top: 40px;
  display: flex;
  justify-content: center;
  width: 80%;
  border-radius: 10px;
  box-shadow: 0 8px 16px 0 rgb(0 0 0 / 5%), 0 6px 20px 0 rgb(0 0 0 /5%);
  margin-bottom: 40px;
  padding: 20px;
}

.centerXY {
  display: flex;
  justify-content: center;
  align-items: center;
}

.badge-text-footer {
  padding: 10px 20px;
}

.badge-text-footer-apps {
  padding: 12px;
}

.correct-answer-mcq {
  border-bottom: 2px solid green;
  border-top: 2px solid green !important;
  background-color: rgba(0, 128, 0, 0.226) !important;
  color: black !important;
}

.bg-green {
  background-color: rgba(0, 128, 21, 0.493) !important;
}

.bg-red {
  background-color: rgba(246, 10, 10, 0.1) !important;
  border: 2px solid rgba(246, 10, 10, 0.507);
}


/*
Buttons
*/

.btn {
    display: inline-block;
    border: none;
    background: black;
    background: var(--secondary-color);
    color: #fff;
    padding: 0.5rem 1.5rem;
  }
  
  .footer-btn-group {
      display: flex;
      justify-content: space-between;
      padding: 0.2rem 2rem;
  }

  .btn svg {
    margin-right: 8px;
  }
  
  .btn-reverse {
    background: #fff;
    color: #000;
  }
  
  .btn-block {
    width: 100%;
    margin:1rem;
    background: #000;
  }
  
  .btn:hover {
    transform: scale(0.98);
    color: #fff;
  }

  .btn-group {
      display:grid;
      grid-template-columns: repeat(100, 100%)
  
  }

  .btn-group button {
      margin: 0.2rem;
      padding: 0.21rem;
  }
  .result-break-down {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }


.quiz-result-main-small {
  margin-top: 20px;
}

/* question card*/

.question-card {
  background: #f3f3f3;
  background: var(--light-color);
  padding: 2rem;
  border-radius: 2rem;
  margin-bottom: 2rem;
}


.result-answer-index {
  position: relative;
  width: 50px;
  height: 50px;
  padding: 10px;
  text-align: center;
  border-radius: 50%;
  font-size: 19px;
  border: 1px #eee solid;
  transition: 0.3s;
  background: black;
  background: var(--secondary-color);
  color: #fff;
}


.result-answer-index-wrong{
  position: relative;
  width: 50px;
  height: 50px;
  padding: 10px;
  text-align: center;
  border-radius: 50%;
  font-size: 19px;
  border: 1px #eee solid;
  transition: 0.3s;
  background: red;
  color: #fff;
}

.result-answer-index-correct{
  position: relative;
  width: 50px;
  height: 50px;
  padding: 10px;
  text-align: center;
  border-radius: 50%;
  font-size: 19px;
  border: 1px #eee solid;
  transition: 0.3s;
  background: green;
  color: #fff;
}


.result-answer-index-UnAnswered{
  position: relative;
  width: 50px;
  height: 50px;
  padding: 10px;
  text-align: center;
  border-radius: 50%;
  font-size: 19px;
  border: 1px #eee solid;
  transition: 0.3s;
  background: gray;
  color: #fff;
}

/* App view  */
.result-answer-index-smallScreen{
  position: relative;
  width: 35px;
  height: 35px;
  padding: 6px;
  text-align: center;
  border-radius: 50%;
  font-size: 12px;
  border: 1px #eee solid;
  transition: 0.3s;
  background: black;
  background: var(--secondary-color);
  color: #fff;
  margin-left: -10px;
}

.result-answer-index-wrong-smallScreen{
  position: relative;
  width: 35px;
  height: 35px;
  padding: 5px;
  text-align: center;
  border-radius: 50%;
  font-size: 12px;
  border: 1px #eee solid;
  transition: 0.3s;
  background: red;
  color: #fff;
}

.result-answer-index-correct-smallScreen{
  position: relative;
  width: 35px;
  height: 35px;
  padding: 5px;
  text-align: center;
  border-radius: 50%;
  font-size: 12px;
  border: 1px #eee solid;
  transition: 0.3s;
  background: green;
  color: #fff;
}


.result-answer-index-UnAnswered-smallScreen{
  position: relative;
  width: 35px;
  height: 35px;
  padding: 5px;
  text-align: center;
  border-radius: 50%;
  font-size: 12px;
  border: 1px #eee solid;
  transition: 0.3s;
  background: gray;
  color: #fff;
}

/* End App View */


@media (max-width: 800px) {
    .quiz-header h2 {
        font-size: 20px;
      }
      .quiz-header h5 {
        font-size: 16px;
      }

  .quiz-result-main {
    margin-top: 100px;
  }
  .quiz-footer-button {
    margin-top: 10px !important;
  }
}

.correct-ans-small-text {
  display: flex;
  justify-content: center;
  margin-top: 5px;
}



.small-result-box {
  width: 30px;
  height: 30px;
  margin: 0px auto;
  display: block;
  font-size: 10px;
  border: 1px solid black;
  border-radius: 10px;
  position: relative;
}

.small-result-box-icon {
  background-color: white;
  color: green;
  position: absolute;
  top: -5px;
  right: -6px;
  font-size: 10px;
}

.app-que-avatar-size {
  width: 20px !important;
  height: 25px !important;
  font-size: 10px !important;
}

h1,
h2,
h3,
h4 {
  font-weight: 600;
  margin-bottom: 10px;
}

.marks-distribution {
  font-size: small;
}

.question-header {
  display: grid;
  grid-template-columns: repeat(2, 50%);
  grid-gap: 2rem;
  gap: 2rem;
}

.question-header-title {
  color: rgb(39, 133, 211);
}

.question-inner-header {
  display: grid;
  grid-template-columns: repeat(2, 50%);
}
.marks-distribution {
  color: crimson;
}

.timer-info {
  color: rgb(39, 133, 211);
}

.result-outlined-box {
  width: 100px;
  height: 100px;
  margin: 0 auto;
  display: block;
  border: 1px solid black;
  border-radius: 10px;
  padding: 20px 10px;
  position: relative;
}


@media (max-width: 576px) {

  .quiz-result-header{
    display: grid;
    grid-template-columns: 1fr;
    border: none;
    margin: 0;
    padding: 0;
    position: relative;
    text-align: left;
    font-family: "Poppins", serif !important;
    z-index: 2;
  }

  .quiz-result-header h2{
    font-size: 20px;
  }
  .quiz-result-header h5{
    font-size: 16px;
  }

  .position-display {
    background: #272668;
    display: inline-block;
    color: #fff;
    font-size: 1rem;
    text-align: center;
    border-radius: 15px;
    margin: 0.1rem;
    align-content: center;
  }
  .number-inside-outline{
    margin-top: -10px;
  }
  .result-break-down {
    display: flex;
    justify-content: space-between;
    margin-left: -40px;
  }
  .result-info {
    display:grid;
    grid-template-columns: 1fr;
    margin: 1rem 0;
    grid-gap:0.5rem;
  }
  .exam-info {
    grid-template-columns: 1fr;
  }
  .question-header {
    display: flex;
    flex-direction: column;
  }
  .question-inner-header {
    display: flex;
    flex-direction: column;
  }
  .result-outlined-box {
    width: 50px;
    height: 50px;
    margin: 0 auto;
    display: block;
    border: 1px solid black;
    border-radius: 10px;
    padding: 5px 2px;
    position: relative;
  }
  .quiz-header {
    margin-top: 100px;
    margin: 1rem;
  }
  .badge-text-footer {
    display: flex;
    justify-content: center;
  }
  .timer-header {
    font-family: Poppins, sans-serif !important;
    font-style: normal !important;
    font-weight: 300px bold !important;
    letter-spacing: 0px;

    font-size: 1rem;
  }

  .mark-dist {
    font-size: 0.6rem;
  }

  .result-break-down h1 {
    font-size: 2rem;
  }
}


body, html {
            height: 100vh;
            margin: 0;
            padding: 0;
}
*{
     box-sizing: border-box;
}
.main-container {
    display: flex;
    width: 100%;
    height: 100%;
}

.event-container {
            width: 80%;
            height: 100%;
}
.event-container-play-video{
    width: 100%;
    height: 100%;
}
.event-body {
            padding: 56.25% 0 0 0;
            position: relative;
}
.event-body iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
}
.chat-container {
           
            flex-grow: 1;
}
.chat-container iframe {
            width: 100%;
            height: 100%;
           
}
@media screen and (max-width: 900px) {
            .main-container {
                flex-flow: column;
            }
            .event-container {
                width: 100%;
            }
        }


.aspect-ratio{
    position: relative;
}

.aspect-ratio::before {
    content: "";
    display: block;
    padding-bottom: calc(100% / (var(--aspect-ratio)));
}

.aspect-ratio > :first-child {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
}
    
iframe, .video-error{
    margin: 0 auto;
    display: block;
 }

.video-error{
    text-align: center;
    color: white;
    top: calc(50% - 10px)!important;
}
.title{font-weight:800;font-size:x-large;text-align:center;font-family:"Noto Serif",serif;margin-top:10px}.dialogue{background-color:#f9f9f9;color:#1683d7;margin-bottom:0}.top-three{margin:2rem;padding:1rem;display:grid;grid-template-columns:repeat(3, 1fr)}.card{background:#fff;margin-bottom:30px;margin-top:0;border-radius:5px;overflow:hidden;box-shadow:0 2px 10px rgba(0,0,0,.1)}.card h6{font-weight:600;color:#1177d6}.num-display{position:relative;width:60px;height:60px;padding:10px;text-align:center;text-align:center;border-radius:30%;font-size:19px;border:1px #eee solid;transition:.3s;background:gray;color:#fff}.num-display{position:absolute;top:-10px;left:-10px}.num-display-own{position:relative;width:60px;height:60px;padding:10px;text-align:center;text-align:center;border-radius:30%;font-size:19px;border:1px #eee solid;transition:.3s;background:#fff;color:#000}.num-display-own{position:absolute;top:-10px;left:-10px}.num-display-top3{position:relative;width:60px;height:60px;padding:10px;text-align:center;border-radius:30%;font-size:20px;border:1px #eee solid;transition:.3s;background:#010136;color:#fff}.num-display-top3{position:absolute;top:-10px;left:-10px}.icon{position:absolute;top:10px;right:40px;cursor:pointer;background:none;border:none}.about-link{display:flex;justify-content:flex-end}

/*
.modal-content {
    border: 0px solid rgba(255, 255, 255, 0.2) !important;
    min-width: 800px !important;
    min-height: 600px !important;
    margin-top: 110px;
    border-radius: 17px !important;
}



.modal {
    padding-right: 0px !important;
}

.modal-dialog {
    display: flex;
    justify-content: center !important;
}

.modal-auth {
    padding: 0px !important;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.1) !important;
}

/*

@media screen and (max-width: 576px) {
   
    .modal-content {
        min-width: 350px !important;
        min-height: 500px !important;
    }
}

@media screen and (max-width: 830px) {
    
    .modal-content {
        min-width: 400px !important;
        min-height: 600px !important;
    }
    
}

*/
.modal-auth-text{margin-left:25px;margin-top:30px}.active-auth-item{border-bottom:3px solid #06c}
