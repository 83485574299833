
body, html {
            height: 100vh;
            margin: 0;
            padding: 0;
}
*{
     box-sizing: border-box;
}
.main-container {
    display: flex;
    width: 100%;
    height: 100%;
}

.event-container {
            width: 80%;
            height: 100%;
}
.event-container-play-video{
    width: 100%;
    height: 100%;
}
.event-body {
            padding: 56.25% 0 0 0;
            position: relative;
}
.event-body iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
}
.chat-container {
           
            flex-grow: 1;
}
.chat-container iframe {
            width: 100%;
            height: 100%;
           
}
@media screen and (max-width: 900px) {
            .main-container {
                flex-flow: column;
            }
            .event-container {
                width: 100%;
            }
        }


.aspect-ratio{
    position: relative;
}

.aspect-ratio::before {
    content: "";
    display: block;
    padding-bottom: calc(100% / (var(--aspect-ratio)));
}

.aspect-ratio > :first-child {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
}
    
iframe, .video-error{
    margin: 0 auto;
    display: block;
 }

.video-error{
    text-align: center;
    color: white;
    top: calc(50% - 10px)!important;
}